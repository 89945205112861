import {
  ConnectionFilter,
  DownloadSolid,
  Snackbar,
  getApiAccountId,
  isNonEmpty,
  useTranslation,
  Button,
} from "@lumar/shared";
import { useSnackbar } from "notistack";
import { useParams } from "react-router-dom";
import {
  useGenerateCreditReportExportsMutation,
  useGeneratedCreditReportExportsQuery,
} from "../../graphql";
import { useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => {
  return {
    button: {
      backgroundColor: "white",
      color: theme.palette.grey[600],
      border: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: theme.shape.borderRadius,
      "&:hover": {
        backgroundColor: theme.palette.grey[50],
      },
      "&:focus": {
        backgroundColor: theme.palette.grey[100],
      },
      "&.Mui-disabled": {
        color: theme.palette.grey[400],
        boxShadow: "none",
        backgroundColor: theme.palette.grey[100],
      },
    },
  };
});

export function CreditReportExport({
  filter,
  from,
  to,
}: {
  filter: ConnectionFilter;
  from: Date;
  to: Date;
}): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("creditUsage");
  const { accountId } = useParams<{ accountId: string }>();
  const apiAccountId = getApiAccountId(accountId);
  const { enqueueSnackbar } = useSnackbar();
  const [isExporting, setIsExporting] = useState(false);

  const [generateExport, { data }] = useGenerateCreditReportExportsMutation();
  const generateExports = async (): Promise<void> => {
    try {
      setIsExporting(true);
      await generateExport({
        variables: {
          accountId: apiAccountId,
          filter: {
            createdAt: { ge: from.toISOString(), le: to.toISOString() },
            ...filter,
          },
        },
      });
    } catch {
      enqueueSnackbar(
        <Snackbar variant="error" title={t("failedCreditReportToGenerate")} />,
      );
    }
  };

  const generateId = data?.createCreditReportExport.creditReportExport.id;

  const { startPolling, stopPolling } = useGeneratedCreditReportExportsQuery({
    variables: {
      id: generateId,
    },
    skip: !generateId,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      const fileURL = isNonEmpty(data.node) ? data.node?.fileURL : undefined;
      const exportingAt = isNonEmpty(data.node)
        ? data.node?.exportingAt
        : undefined;
      if (exportingAt && !fileURL) {
        startPolling(1000);
      } else {
        stopPolling();
        if (fileURL) {
          setIsExporting(false);
          window.location.href = fileURL;
        }
      }
    },
  });

  return (
    <Button
      variant="outlined"
      onClick={async () => await generateExports()}
      aria-label="Generate credit usage report"
      data-testid="credit-report-export-btn"
      loading={isExporting}
      className={classes.button}
    >
      <DownloadSolid fontSize="small" />
    </Button>
  );
}
