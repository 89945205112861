import React from "react";
import {
  Button,
  ComboBox,
  fieldToTextField,
  InfoOutlined,
  TextField,
  Typography,
  Trans,
  useTranslation,
  useSession,
  Checkbox,
  formToCheckboxProps,
} from "@lumar/shared";
import {
  CircularProgress,
  FormControlLabel,
  makeStyles,
  Tooltip,
} from "@material-ui/core";
import { Field, FieldProps, Form, Formik } from "formik";
import { getAlpha2Codes } from "i18n-iso-countries";

import { useAccountFormValues } from "./data/useAccountFormValues";
import { ImageInput } from "./ImageInput";
import { RoleCode } from "../graphql";
import { TopNavigation } from "../_common/top-navigation/TopNavigation";
import { useAccountSchema } from "./data/useAccountSchema";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
    maxWidth: 756,
    minWidth: 300,
    padding: theme.spacing(5, 0, 4.25, 0),
  },
  container: {
    background: theme.palette.background.paper,
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.palette.grey[300],
    borderRadius: theme.spacing(1),
    boxShadow: theme.shadows[1],
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(30),
    [theme.breakpoints.down(1080)]: {
      paddingRight: theme.spacing(3),
      paddingTop: theme.spacing(20),
    },
  },
  accountName: {
    display: "block",
    margin: theme.spacing(4, 0, 3, 0),
  },
  proxyInput: {
    maxWidth: 410,
    display: "block",
  },
  countryInput: {
    maxWidth: 410,
    marginTop: theme.spacing(2),
  },
  labelIcon: {
    marginLeft: theme.spacing(0.5),
    verticalAlign: "text-bottom",
  },
  tooltip: {
    whiteSpace: "pre-line",
    minWidth: 480,
    maxWidth: 480,
  },
  checkbox: {
    display: "block",
    marginTop: theme.spacing(2),
  },
  logoContainer: {
    width: 191,
    height: 213,
    position: "absolute",
    top: 0,
    right: 24,
    boxShadow:
      "0px 10px 15px -3px rgba(0, 0, 0, 0.12), 0px 1px 2px rgba(0, 0, 0, 0.08)",
    borderRadius: theme.spacing(1),
    [theme.breakpoints.down(1080)]: {
      right: "calc(50% - 95px)",
    },
  },
  fileInput: {
    position: "absolute",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    opacity: 0,
    cursor: "pointer",
  },
  saveButton: {
    margin: theme.spacing(3, 0, 4, 0),
  },
}));

const COUNTRIES_LIST = Object.keys(getAlpha2Codes());

export function AccountPage(): JSX.Element {
  const classes = useStyles();
  const { t } = useTranslation("account");

  const { hasSufficientRole } = useSession();

  const { formValues, account, saveFormValues, loading } =
    useAccountFormValues();

  const countries = COUNTRIES_LIST.map((country) => ({
    value: country,
    name: t("countryValue", { value: country }),
  }));

  const readOnyl = !hasSufficientRole(RoleCode.Admin);

  const validationSchema = useAccountSchema();

  return (
    <>
      <TopNavigation title={t("title")} />
      {loading ? (
        <CircularProgress aria-label="Loading account settings" />
      ) : (
        <Formik
          initialValues={formValues}
          enableReinitialize
          validationSchema={validationSchema}
          onSubmit={async (values) => {
            await saveFormValues(values);
          }}
        >
          {({ submitForm, isSubmitting }) => (
            <Form>
              <div className={classes.root}>
                <div className={classes.container}>
                  <Typography
                    variant="h6Medium"
                    className={classes.accountName}
                    data-testid="account-name"
                  >
                    {account.name}
                  </Typography>

                  <Field name="proxy">
                    {(props: FieldProps<string | undefined>) => (
                      <TextField
                        {...fieldToTextField(props)}
                        label={
                          <>
                            {t("proxyLabel")}
                            <Tooltip
                              title={
                                <Trans ns="account" i18nKey="proxyTooltip" />
                              }
                              classes={{ tooltip: classes.tooltip }}
                              placement="top"
                            >
                              <span tabIndex={0}>
                                <InfoOutlined
                                  color="inherit"
                                  fontSize="inherit"
                                  className={classes.labelIcon}
                                />
                              </span>
                            </Tooltip>
                          </>
                        }
                        placeholder={t("proxyPlaceholder")}
                        className={classes.proxyInput}
                        inputProps={{
                          readOnly: readOnyl,
                        }}
                        data-testid="account-proxy"
                      />
                    )}
                  </Field>

                  <Field name="country">
                    {({
                      field: { name, value },
                      form: { setFieldValue, isSubmitting },
                    }: FieldProps<string | undefined>) => (
                      <ComboBox
                        value={countries.find((x) => x.value === value)}
                        onChange={(_, value) =>
                          setFieldValue(name, value.value)
                        }
                        options={countries}
                        getOptionLabel={(option) => option.name}
                        inputProps={{
                          label: t("countryLabel"),
                        }}
                        disabled={isSubmitting}
                        readOnly={readOnyl}
                        disableClearable
                        className={classes.countryInput}
                        data-testid="account-settings-country"
                      />
                    )}
                  </Field>

                  <Field name="aiFeaturesEnabled">
                    {(props: FieldProps<boolean>) => (
                      <FormControlLabel
                        control={
                          <Checkbox
                            {...formToCheckboxProps(
                              props.form,
                              "aiFeaturesEnabled",
                            )}
                          />
                        }
                        label={
                          <>
                            {t("aiFeaturesEnabledLabel")}
                            <Tooltip
                              title={t("aiFeaturesEnabledDescription")}
                              placement="top"
                            >
                              <span tabIndex={0}>
                                <InfoOutlined
                                  color="inherit"
                                  fontSize="inherit"
                                  className={classes.labelIcon}
                                />
                              </span>
                            </Tooltip>
                          </>
                        }
                        className={classes.checkbox}
                      />
                    )}
                  </Field>

                  <Field name="logo">
                    {({
                      field: { value, name },
                      form: { setFieldValue, isSubmitting },
                    }: FieldProps<string | File | undefined>) => {
                      return (
                        <div className={classes.logoContainer}>
                          <ImageInput
                            value={value}
                            onChange={(file) => setFieldValue(name, file)}
                            disabled={isSubmitting || readOnyl}
                            placeholder={t("logoPlaceholder")}
                            data-testid="account-logo"
                            alt={t("logoAlt")}
                          />
                        </div>
                      );
                    }}
                  </Field>

                  <Button
                    onClick={() => !isSubmitting && submitForm()}
                    loading={isSubmitting}
                    disabled={readOnyl}
                    variant="contained"
                    color="primary"
                    className={classes.saveButton}
                    data-testid="account-save"
                  >
                    {t("save")}
                  </Button>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      )}
    </>
  );
}
