import { useTranslation } from "@lumar/shared";
import { useMemo } from "react";
import { GetCreditUsageDataQuery, CreditAllocationType } from "../graphql";

export interface CreditUsageRows {
  id: string;
  crawlId: string;
  startedAt: string;
  finishedAt: string;
  domainName: string;
  projectName: string;
  username: string;
  creditsUsed: number;
  creditAllocationType: CreditAllocationType;
  isDeleted: boolean;
}

export function useCreditUsageRows(
  data?: GetCreditUsageDataQuery,
): CreditUsageRows[] {
  const { t } = useTranslation("common");
  const rows = useMemo(
    () =>
      data?.getAccount?.creditReports?.edges.map((creditReport) => ({
        id: creditReport.cursor,
        crawlId: creditReport.node.crawlId ?? "",
        startedAt: creditReport.node.createdAt
          ? t("dateTime", { value: new Date(creditReport.node.createdAt) })
          : "",
        finishedAt: creditReport.node.finishedAt
          ? t("dateTime", { value: new Date(creditReport.node.finishedAt) })
          : "",
        domainName: creditReport.node.project?.primaryDomain ?? "",
        projectName: creditReport.node.project?.name ?? "",
        username:
          creditReport.node.crawl?.createdByUser?.email ||
          creditReport.node.crawl?.createdByUser?.id.toString() ||
          "",
        creditsUsed: creditReport.node.creditsUsed ?? 0,
        isDeleted: creditReport.node.crawl === null,
        creditAllocationType: creditReport.node.creditAllocationType,
      })) || [],
    [data, t],
  );

  return rows;
}
