/* eslint-disable react/display-name */
import React from "react";
import { getRawCrawlId, useTranslation } from "@lumar/shared";
import { GridColumns } from "@mui/x-data-grid-pro";
import { CrawlFinishedCell } from "./cells/CrawlFinishedCell";
import { CrawlIdCell } from "./cells/CrawlIdCell";
import { CrawlStartedByCell } from "./cells/CrawlStartedByCell";
import { CrawlStartedCell } from "./cells/CrawlStartedCell";
import { CreditsCell } from "./cells/CreditsCell";
import { ProjectCell } from "./cells/ProjectCell";
import { CreditAllocationTypeCell } from "./cells/CreditAllocationTypeCell";

export function useCreditUsageColumns(): GridColumns {
  const { t } = useTranslation("creditUsage");
  const columns: GridColumns = [
    {
      field: "project",
      headerName: "Project",
      flex: 1.5,
      width: 300,
      minWidth: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableExport: false,
      valueGetter: ({ row: { projectName } }) => projectName,
      renderCell: (props) => <ProjectCell {...props} />,
    },
    {
      field: "domain",
      headerName: "Domain",
      valueGetter: ({ row: { domainName } }) => domainName,
      hide: true,
      disableExport: false,
    },
    {
      field: "crawlId",
      headerName: "Crawl ID",
      flex: 0.5,
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableExport: false,
      valueGetter: ({ row: { crawlId } }) => {
        return getRawCrawlId(crawlId);
      },
      renderCell: (props) => <CrawlIdCell {...props} />,
    },
    {
      field: "creditsUsed",
      headerName: "Credits",
      flex: 0.5,
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      disableExport: false,
      valueGetter: ({ row: { creditsUsed } }) => creditsUsed,
      renderCell: (props) => <CreditsCell {...props} />,
    },
    {
      field: "creditAllocationType",
      headerName: "Type",
      flex: 0.5,
      width: 90,
      headerAlign: "center",
      align: "center",
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      disableExport: false,
      valueGetter: ({ row: { creditAllocationType } }) => creditAllocationType,
      renderCell: (props) => <CreditAllocationTypeCell {...props} />,
    },
    {
      field: "createdAt",
      headerName: "Started",
      flex: 0.8,
      width: 210,
      minWidth: 100,
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      disableExport: false,
      valueGetter: ({ row: { startedAt } }) => {
        return t("dateWithTime", { date: startedAt });
      },
      renderCell: (props) => <CrawlStartedCell {...props} />,
    },
    {
      field: "finishedAt",
      headerName: "Finished",
      flex: 0.8,
      width: 210,
      minWidth: 100,
      sortable: true,
      filterable: true,
      disableColumnMenu: true,
      disableExport: false,
      valueGetter: ({ row: { finishedAt } }) => {
        return t("dateWithTime", { date: finishedAt });
      },
      renderCell: (props) => <CrawlFinishedCell {...props} />,
    },
    {
      field: "startedBy",
      headerName: "Started by",
      flex: 1,
      width: 210,
      minWidth: 100,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      disableExport: false,
      valueGetter: ({ row: { username } }) => username,
      renderCell: (props) => <CrawlStartedByCell {...props} />,
    },
  ];

  return columns;
}
